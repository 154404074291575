import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ShortenerForm from '../components/shortenerForm'
import Lightbox from '../components/lightbox'
import SubFooter from '../components/subFooter'
import HomeFAQBanner from '../components/HomeFAQBanner'
import Footer from '../components/footer'
import Link from '../components/anchor'
import HomeContactSalesBanner from "../components/HomeContactSalesBanner";
import DomainUpsellBanner from '../components/DomainUpsellBanner'
import { Divider } from '@rebrandly/styleguide'
import TestimonialsAndProof from '../components/TestimonialsAndProof'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const VIDEOS = [
  {
    src: 'https://www.youtube.com/embed/LesnixX76YY?autoplay=1',
    thumbnail: 'https://i.ytimg.com/vi/LesnixX76YY/hqdefault.jpg'
  }
]

class FreeUrlShortener extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
  }

  componentDidMount () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'optimize.activate' })
  }

  render () {
    const { isOpen } = this.state
    return (
      <GoogleReCaptchaProvider reCaptchaKey='6LcKApAqAAAAAHavw1V1f5eliKzxKcnEV6Dwo00x'><Layout
        type='empty'
        noMeta
      >
        <Helmet bodyAttributes={{
          class: 'body-no-padding'
        }}
        >
          <title>Free URL Shortener</title>
          <meta
            content='Need to shorten a URL? Use RB.GY for free, memorable shortened links that are easy to track. Ideal for social media, emails, and ads.'
          />
          <meta
            content='Free link shortener: Fast & simple short URLs'
            property='og:title'
          />
          <meta
            content='Need to shorten a URL? Use RB.GY for free, memorable shortened links that are easy to track. Ideal for social media, emails, and ads.'
            property='og:description'
          />
          <meta property='og:image' content='https://free-url-shortener.rb.gy/url-shortener.png' />
          <meta property='og:url' content='https://free-url-shortener.rb.gy' />
          <meta property='og:type' content='website' />
          <meta content='summary' name='twitter:card' />
          <meta content='width=device-width, initial-scale=1' name='viewport' />
          <link href='images/rebrandly-webclip.png' rel='apple-touch-icon' />
          <style>
            {`.freetooltip {
              position: relative;
          }
          
          .freetooltip .tooltiptext {
              visibility: hidden;
              /* min-width: 260px; */
              background-color: #fff;
              border: 1px solid #E8E9EA;
              box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
              font-size: 15px;
              color: #212732;
              text-align: left;
              border-radius: 6px;
              padding: 6px 12px;
              /* Position the tooltip */
              position: absolute;
              z-index: 1;
              bottom: 100%;
              left: 20%;
              margin-left: -130px;
              margin-bottom: -1px;
          }
          
          @media only screen and (max-width: 1450px) {
              .freetooltip .tooltiptext {
                  visibility: hidden;
                  /* min-width: 260px; */
                  background-color: #fff;
                  border: 1px solid #E8E9EA;
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
                  font-size: 15px;
                  color: #212732;
                  text-align: left;
                  border-radius: 6px;
                  padding: 6px 12px;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 100%;
                  margin-left: -100%;
              }
          }
          
          @media only screen and (max-width: 991px) {
              .freetooltip .tooltiptext {
                  visibility: hidden;
                  min-width: 260px;
                  background-color: #fff;
                  border: 1px solid #E8E9EA;
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
                  font-size: 15px;
                  color: #212732;
                  text-align: left;
                  border-radius: 6px;
                  padding: 6px 12px;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  bottom: 100%;
                  left: 50%;
                  margin-left: -130px;
                  margin-bottom: 4px;
              }
          }
          
          .freetooltip:hover .tooltiptext {
              visibility: visible;
          }`}
          </style>
        </Helmet>
        <ShortenerForm
          className='section free-url-shortener'
          title={<h1 className='white-text'>Best Free URL Shortener: Track & Optimize Links</h1>}
          description={
            <h2 className='terms-copy terms-copy-variant'>
              Shorten links for free with  <Link href='http://rb.gy' title='http://RB.GY'>RB.GY</Link> powered by <Link href='https://rebrandly.com' title='URLs shortener | Rebrandly'>Rebrandly</Link>. Create short & memorable links in seconds.
            </h2>
          }
          placeholder='Enter link here'
          sitekey='6LcKApAqAAAAAHavw1V1f5eliKzxKcnEV6Dwo00x'
          cookie='links-data-external'
          limitLinksDisplay={5}
        />

        <HomeContactSalesBanner />
        <TestimonialsAndProof/>
        <div className='Container--wide free-url-shortener__divider-container'>
          <Divider/>
        </div>
        <DomainUpsellBanner/>
        <HomeFAQBanner />
        <Footer />
        <SubFooter />
        <Lightbox
          open={isOpen}
          onCloseRequest={() => this.setState({ isOpen: false })}
          mainSrc={0}
          elements={VIDEOS}
        />
      </Layout></GoogleReCaptchaProvider>
    )
  }
}

export default FreeUrlShortener
