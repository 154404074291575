import React from 'react'
import { A, Button, SpotIllustration, Text } from '@rebrandly/styleguide'
import './HomeContactSalesBanner.css'
import Link from "./anchor";

const HomeContactSalesBanner = () => {
  return (
    <div className='HomeContactSalesBanner'>
      <div>
        <div className='HomeContactSalesBanner__paragraph container--half-left'>
          <h2 className='new_h2_dark-_left no-margin'>
            <strong className='new_h2_dark'>A fast, easy, and free link shortener</strong>
          </h2>
          <br/>
          <p className='paragraph_dark_left'>
            Use this free URL shortener to change long, ugly links into memorable and trackable short URLs. <Link href='http://rb.gy/' title='http://RB.GY'>RB.GY</Link> is the best free link shortener alternative to Bitly, Tinyurl, and Google link shorteners.
          </p>
          <h2 className='new_h2_dark-_left no-margin'>
            <strong className='new_h2_dark'>Shorten links, then track them</strong>
          </h2>
          <br/>
          <p className='paragraph_dark_left'>
            Free short links for any social media platform, website, SMS, email, ads, and more. Generate short links for
            Instagram, LinkedIn, Facebook, X, TikTok and more.
          </p>
          <br/>
          <p className='paragraph_dark_left'>
            Turn short links into QR codes with our <Link href='https://free-url-shortener.rb.gy/qr-code-generator' title='https://free-url-shortener.rb.gy/qr-code-generator'>free QR code generator</Link>. Shorten a link, then track it to <Link href='https://free-url-shortener.rb.gy/stats' title='https://free-url-shortener.rb.gy/stats'>see how many clicks it received</Link>.
          </p>
        </div>
      </div>

      <div>
        <div className='HomeContactSalesBanner__contact container--half-right full-height'>
          <SpotIllustration className='HomeContactSalesBanner__contact--ill' name='ill-spot-contact' size='sm'/>
          <div>
            <Text className='Text--Detail m-b-16' size='large'>
              Experience the <strong> benefits of link management </strong> for your business
            </Text>

            <div className='HomeContactSalesBanner__CTAs'>
              <A href='https://rebrandly.sale/premium' target='_blank'>
                <Button label='Try Rebrandly' theme='primary' size='lg'/>
              </A>
              <A href='https://rebrandly.com/book-a-demo' target='_blank'>
                <Button label='Talk to Sales' size='lg' className='HomeContactSalesBanner__CTAs__Secondary'/>
              </A>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeContactSalesBanner
