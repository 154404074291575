import React, {Component} from 'react'
import {node, string, number, func} from 'prop-types'
import axios from 'axios'
import {setCookie, getCookie, eraseCookie} from '../../static/js/mixins'
import {isURL} from 'validator'
import get from 'lodash/get'
import URL from 'url-parse'
import Link from './anchor'
import blacklist from '../utils/blacklist'
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import SignUpButton from './SignUpButton'
import NotificationToast from './NotificationToast'
import {
  A,
  Modal,
  ModalContent,
  Button,
  Icon,
  Text,
  SpotIllustration
} from '@rebrandly/styleguide'
import {encryptWithSHA256, isTestEnv, LIMIT_LINKS_DISPLAY} from '../utils/common'
import config from '../config'

import './shortenerForm.css'
import ShortenedLink from './ShortenedLink'
import {LazyLoadImage} from 'react-lazy-load-image-component'

const FREE_LINKS_LIMIT = 5
const FREE_LINKS_COOKIE = encryptWithSHA256('free_links_created')

let wasChromeExtensionBannerOpened = false;

class ShortenerForm extends Component {
  constructor(props) {
    super(props)
    this.LINKS_COOKIE = encryptWithSHA256(props.cookie || 'links-data')
    let cache = JSON.parse(getCookie(props.cookie || 'links-data'))
    let countOld = getCookie('free_links_created')

    if (cache) {
      setCookie(this.LINKS_COOKIE, cache, 30)
      eraseCookie(this.props.cookie || 'links-data')
    } else {
      cache = JSON.parse(getCookie(this.LINKS_COOKIE))
    }

    if (countOld) {
      setCookie(FREE_LINKS_COOKIE, countOld)
      eraseCookie('free_links_created')
    }

    this.state = {
      mergedLinks: cache && cache.entries && cache.entries.length > 0 ? {entries: cache.entries.filter(k => !!k.id)} : {'entries': []},
      destination: '',
      isLoading: false,
      links: [],
      notify: {},
      showExtensionBanner: false,
      extension: {
        label: 'Get Chrome extension',
        href: config.extensions.chrome.url,
        target: '_blank',
        iconName: 'ic-browser-chrome'
      },
      extensionBannerClosed: false,
      isRenderLimitPopUpOpen: false,
    }
    this.endPoint = isTestEnv() ? 'https://free-url-shortener.test.rb.gy/shorten' : 'https://free-url-shortener.rb.gy/shorten'
  }

  componentDidMount() {
    import('../utils/browser').then(({name, isMobile}) => {
      this.name = name
      this.isMobile = isMobile
      this.getBrowserName()
    })
  }

  onSubmit(event) {
    event.preventDefault()
    this.setState({isLoading: true})
    const url = this.state.destination.startsWith('http') ? this.state.destination : `http://${this.state.destination}`
    const {hostname} = new URL(url, {}, true)

    // If it's not a URL or if it's blacklisted => Invalid URL
    if (!isURL(url, {allow_underscores: true}) || blacklist.some((_host) => _host === hostname)) {
      this.setState((state) => ({...state, notify: {message: 'Invalid URL', type: 'error'}, isLoading: false}))
      return false
    }

    // If already present on the list => Already shortened
    if (this.state.mergedLinks.entries.some(entry => entry.destination === url)) {
      this.setState((state) => ({...state, notify: {message: 'Already shortened', type: 'error'}, isLoading: false}))
      return false
    }

    // If the limit creation was reached, show the Pop-up limit modal
    if (parseInt(getCookie(FREE_LINKS_COOKIE)) >= FREE_LINKS_LIMIT) {
      this.setState((state) => ({
        ...state,
        destination: '',
        isLoading: false,
        isRenderLimitPopUpOpen: true
      }))
      return
    }

    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      this.setState((state) => ({
        ...state,
        notify: {
          message: "Recaptcha couldn't identify you as a person, please refresh the page!",
          type: 'error'
        }
      }))
      console.error('Recaptcha has not been loaded');
      return;
    }

    executeRecaptcha('shortener_form')
      .then(token => {
        if (token) {
          this.onRecaptchaSuccessVerify(token)
        } else {
          this.setState((state) => ({
            ...state,
            notify: {
              message: "Recaptcha couldn't identify you as a person, please refresh the page!",
              type: 'error'
            }
          }))
        }
      });
  }

  onRecaptchaSuccessVerify(value) {
    const headers = {
      'g-recaptcha-response-v3': value,
      'content-type': 'application/x-www-form-urlencoded'
    }
    const destination = (this.state.destination.startsWith('http://') || this.state.destination.startsWith('https://')) ? this.state.destination : 'http://' + this.state.destination
    const jsonData = {
      destination
    }
    const axiosData = {
      method: 'post',
      url: this.endPoint,
      headers,
      data: jsonData
    }

    axios(axiosData).then(({data}) => {
      const shiftedLinks = {entries: [...this.state.mergedLinks.entries.slice(0, this.props.limitLinksDisplay - 1 || LIMIT_LINKS_DISPLAY - 1)]}
      shiftedLinks.entries.unshift({
        destination,
        shortUrl: data.shortUrl,
        id: data.id
      })
      setCookie(this.LINKS_COOKIE, JSON.stringify(shiftedLinks), 30)
      setCookie(FREE_LINKS_COOKIE, ((parseInt(getCookie(FREE_LINKS_COOKIE)) + 1) || this.state.mergedLinks.entries.length + 1).toString())
      this.setState((state) => ({
        ...state,
        destination: '',
        isLoading: false,
        mergedLinks: shiftedLinks,
        hiddenInput: data.shortUrl
      }))
      this.copyToClipboard(this.referral)
      this.debounceBanner()
    }).catch(error => {
      console.error('Shorten link API error: ', error);
      this.setState((state) => ({
        ...state,
        notify: {
          message: "Something went wrong on our side, please try again.",
          type: 'error'
        }
      }))
    })
  }

  handleHideLink = (index) => {
    const _entries = this.state.mergedLinks.entries.slice(0)
    _entries.splice(index, 1)
    const merge = {entries: _entries}
    this.setState({
      mergedLinks: merge
    })
    setCookie(this.LINKS_COOKIE, JSON.stringify(merge), 30)
  }

  renderShortenedLinks() {
    return this.state.mergedLinks.entries.slice(0, this.props.limitLinksDisplay || LIMIT_LINKS_DISPLAY).map((entry, index) => this.renderShortenedLink(entry, index))
  }

  renderShortenedLink({destination, shortUrl}, index) {
    if (shortUrl) {
      return (
        <ShortenedLink destination={destination} onHide={this.handleHideLink} index={index} shortUrl={shortUrl}/>
      )
    } else {
      return null
    }
  }

  renderLimitBanner() {
    return (
      <div className='rebrand-link-limit-reached-banner'>
        <div className='ShortenerForm rebrand-link-limit-reached-banner-container'>
          <div className='rebrand-link-limit-reached-banner-copy'>
            <div className='titles'>
              <h3>
                Take your business to the next level!
              </h3>
              <h5 className='cta-description'>
                Want to brand your short links, create custom QR codes, and access detailed analytics?
                Get Rebrandly for your business and supercharge your link management.
              </h5>
            </div>

            <div className='rebrand-link-limit-reached-banner-cta'>
              <Link
                href='https://rebrandly.sale/premium'
                target='_blank'
                className='premium-btn-cta btn-secondary btn-inverted'
              >
                Try Rebrandly
              </Link>
              <div>
                <span className='font-small'>&nbsp;or&nbsp;</span>
                <a href='https://rebrandly.com/book-a-demo' className='signup-btn-cta btn-inverted' target='_blank'>
                  Talk to sales
                </a>
              </div>
            </div>
          </div>

          <LazyLoadImage
            className='ShortenerForm rebrand-link-limit-reached-banner-img'
            src='../images/IMG_supergirl@2x.png'
            width={241}
            height={188}
            alt='Short link'
            title='Short link'
          />
        </div>
      </div>
    )
  }

  renderLimitPopup() {
    return (
      <Modal size='md' isOpen={this.state.isRenderLimitPopUpOpen} showClose
             onClose={() => this.setState({isRenderLimitPopUpOpen: false})}>
        <ModalContent>
          <div>
            <div className='AlertPanel AlertPanel--md'>

              <div className='AlertPanel__icon'>
                <SpotIllustration name='ill-spot-morelinks' color='var(--color-blue-50)' size={'sm'}/>
              </div>

              <div className='AlertPanel__title'>
                Need to shorten more links?
              </div>

              <div className='AlertPanel__message'>
                <div className='message-top'>
                  <Text>You’ve shortened 5 links, the limit for users without an account.
                    Sign up for <A size='large' target='_blank' href='https://www.rebrandly.com'>Rebrandly</A> to
                    shorten more links and unlock advanced features.
                    With our free plan, you can:
                  </Text>
                </div>
                <div className='features-block m-t-12'>
                  <div className='feature-column'>
                    <Text size='small'>Shorten <span className='Text-strong'>10</span> links/mo</Text>
                  </div>
                  <div className='feature-column'>
                    <Text size='small'>Brand links and create custom slugs</Text>
                  </div>
                  <div className='feature-column'>
                    <Text size='small'>Generate QR codes</Text>
                  </div>
                  <div className='feature-column'>
                    <Text size='small'>Track clicks</Text>
                  </div>
                  <div className='feature-column'>
                    <Text size='small'>...and so much more.</Text>
                  </div>
                </div>
                <Text size='small' className='Text-strong m-t-24'>Your shortened links will be imported into your
                  account</Text>
              </div>

              <div className='AlertPanel__cta'>
                <SignUpButton size='lg' theme='primary' className='m-t-12' label='Create your free account'
                              linksIds={this.state.mergedLinks.entries.map(k => k.id)}/>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    )
  }

  removeNotification = () => this.setState({notify: {}})

  renderNotification() {
    if (this.state.notify.type) {
      return (
        <NotificationToast
          type={this.state.notify.type}
          message={this.state.notify.message}
          onDelete={this.removeNotification}
        />
      )
    }
  }

  debounceBanner = () => {
    // check if Chrome extension is installed hence not showing popup
    const extensionIds = [
      config.extensions.chrome.id
    ]
    if (this.state.extensionBannerClosed || this.isMobile) return

    extensionIds.forEach((extension) => {
      try {
        const request = {sources: ['window', 'screen', 'tab']}
        window.chrome.runtime.sendMessage(extension, request, () => {
          return false
        })
      } catch (e) {
        setTimeout(() => {
            if (!wasChromeExtensionBannerOpened) {
              this.toggleExtensionBanner()
              wasChromeExtensionBannerOpened = true;
            }
          }, 5000
        )
      }
    })
  }

  toggleExtensionBanner = () => {
    this.setState({
      showExtensionBanner: !this.state.showExtensionBanner
    })
  }

  handleCloseExtensionBanner = () => {
    this.setState({
      showExtensionBanner: false,
      extensionBannerClosed: true
    })
  }

  getBrowserName = () => {
    var extension
    switch (this.name) {
      case 'firefox':
        extension = {
          label: 'Get Firefox extension',
          href: config.extensions.firefox.url,
          target: '_blank',
          iconName: 'ic-browser-firefox'
        }
        break

      case 'microsoft edge':
        extension = {
          label: 'Get Edge extension',
          href: config.extensions.edge.url,
          target: '_blank',
          iconName: 'ic-browser-edge'
        }
        break

      default:
        extension = {
          label: 'Get Chrome extension',
          href: config.extensions.chrome.url,
          target: '_blank',
          iconName: 'ic-browser-chrome'
        }
        break
    }
    this.setState({extension})
  }

  copyToClipboard(referral) {
    // e.preventDefault()
    referral.select()
    referral.setSelectionRange(0, 99999)
    document.execCommand('copy')
  }

  render() {
    return (
      <div className={`form form-small-pad shorten-section ${this.props.className}`}>
        <Modal className='Modal--top' size='sm' isOpen={this.state.showExtensionBanner}
               onClose={this.handleCloseExtensionBanner} showClose>
          <ModalContent>
            <div className='flex flex-column'>
              <Text size='medium' className='m-b-16 center-xs'>
                Quickly shorten and share long URLs in one easy click using the Rebrandly browser extension
              </Text>
              <div className='flex center-xs'>
                <Icon name={get(this.state.extension, 'iconName')} size='small'/>
              </div>
              <Button theme='primary' onClick={() => window.open(get(this.state.extension, 'href'), '_blank')}
                      className='m-t-24' label={get(this.state.extension, 'label')}/>
            </div>
          </ModalContent>
        </Modal>
        {this.props.title || null}
        <div className='container hero'>
          <div className='full-width'>
            <div className='rebrand-link-block home'>
              <div className='w-form'>
                {this.props.description || null}
                <form
                  onSubmit={(e) => this.onSubmit(e)}
                  id='wf-form-rebrand-link-form'
                  name='wf-form-rebrand-link-form'
                  data-name='rebrand-link-form'
                  className='form-link-creation home'
                >
                  {this.renderNotification()}
                  <label htmlFor='url' className='text-indent w-hidden-small'>Destination</label>
                  <input
                    type='text'
                    className='form-link-creation-input w-input'
                    maxLength={256}
                    name='url'
                    data-name='Url'
                    placeholder={this.props.placeholder || 'Make your links shorter'}
                    id='url'
                    value={this.state.isLoading ? '' : this.state.destination}
                    onChange={e => this.setState({destination: e.target.value})}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    disabled={this.state.isLoading}
                  />
                  <input
                    type='text'
                    className='clipboard-input'
                    value={this.state.hiddenInput}
                    ref={ref => (this.referral = ref)}
                  />
                  <input
                    type='submit'
                    value={this.state.isLoading ? 'Loading...' : 'Shorten URL'}
                    disabled={this.state.isLoading}
                    className='btn-primary-3 w-button'
                  />
                </form>
                <div className='shortened-links-list'>
                  {this.renderShortenedLinks()}
                </div>
                <div className='terms-copy'>
                  By clicking Shorten URL, you agree to Rebrandly's <Link href='https://rebrandly.com/terms-conditions'
                                                                          target='_blank' className='terms-link'>Terms
                  of Use</Link>, <Link href='https://rebrandly.com/privacy-policy' target='_blank'
                                       className='terms-link'>Privacy Policy</Link> and <Link
                  href='https://rebrandly.com/cookies' target='_blank' className='terms-link'>Cookie Policy</Link>
                </div>
              </div>
            </div>
            {this.renderLimitBanner()}
            {this.renderLimitPopup()}
          </div>
        </div>
      </div>
    )
  }
}

ShortenerForm.propTypes = {
  title: node,
  placeholder: string,
  cookie: string,
  description: node,
  limitLinksDisplay: number,
  sitekey: string,
  className: string
}

export default withGoogleReCaptcha(ShortenerForm)
